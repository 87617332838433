/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { LoggedInUserInfo, OrganisationMembershipApi } from 'network/api-types';
import { productsProvideFeature } from './products';
import { roleProvidesFeature } from './roles';

let loggedInUserInfo: LoggedInUserInfo | null = null;

export function setLoggedInUserInfo(userInfo: LoggedInUserInfo | null) {
	loggedInUserInfo = userInfo;
}
export function getLoggedInUserInfo() {
	return loggedInUserInfo;
}
export function isUserLoggedIn() {
	return loggedInUserInfo !== null;
}

// Get url parameters and page name
export const urlParams = new URLSearchParams(window.location.search);
const [hasHtmlExt, pageStemName] = ((): [boolean, string] => {
	const pathname = window.location.pathname;
	const lastPathSlashPos = pathname.lastIndexOf('/');
	const pageName = pathname.substring(lastPathSlashPos + 1);
	const hasHtmlExt = pageName.endsWith('.html');
	return [
		hasHtmlExt,
		hasHtmlExt ? pageName.substring(0, pageName.length - 5) : pageName
	];
})();
export const urlParamsFirstElement = (() => {
	const iter = urlParams.entries().next();
	return (
		iter.done
		|| iter.value[1] !== '' // When the first key has a non empty value assigned then the key is not the value we are interested in.
	) ? null : iter.value[0];
})();

// code for redirects
const isShowRedirect = pageStemName === 'show' && !hasHtmlExt;
const isPlayRedirect = pageStemName === 'play' && !hasHtmlExt;

const LanguageKey = 'gc-settingsLang';

const DEV_PATH_PREFIX = '/dev/';

export function extractBranchFromPath(path?: string) {
	if (path === undefined) {
		return null;
	}
	if (path.startsWith(DEV_PATH_PREFIX)) {
		const indexNextSlash = path.indexOf('/', DEV_PATH_PREFIX.length);
		return path.substring(DEV_PATH_PREFIX.length, indexNextSlash === -1 ? path.length : indexNextSlash);
	} else {
		return null;
	}
}

// Get parameters and features from URL
export class Parameters {
	static readonly storageLocation = urlParams.get('storage');
	static readonly remoteCamId = urlParams.get('remoteCam');
	static readonly auth = urlParams.get('auth');
	static readonly jsId = urlParams.get('jsId');
	static readonly isUnderTest = urlParams.get('__testing__') !== null;
	static readonly workspaceIdFromUrl = isShowRedirect ?
		urlParamsFirstElement :
		urlParams.get('ws');
	static readonly primaryWorkspaceIdFromUrl = urlParams.get('pws');
	static readonly gameIdFromUrl = isPlayRedirect ?
		urlParamsFirstElement :
		urlParams.get('game');
	static readonly forumUriFromUrl = urlParams.get('forum');
	static readonly devBranch = extractBranchFromPath(window.location.pathname);
	static get customExampleWorkspaceId() {
		return urlParams.get('ews') || loggedInUserInfo?.attributes.get('exampleWs') || null;
	}
	static get customTemplateWorkspaceId() {
		return urlParams.get('tws') || loggedInUserInfo?.attributes.get('templateWs') || null;
	}
	static readonly trailer = urlParams.get('trailer');
	static readonly trailerDelay = urlParams.get('trailerDelay');
	static readonly llmAccount = urlParams.get('llmAccount');
	static readonly llmAuth = urlParams.get('llmAuth');
	static get language() {
		return (
			urlParams.get('lang') ??
			localStorage.getItem(LanguageKey) ??
			navigator.language.substring(0, 2)
		);
	}
	static set language(lang: string) {
		localStorage.setItem(LanguageKey, lang);
		location.reload();
	}
}
if (Parameters.workspaceIdFromUrl) {
	console.info(`From URL: workspaceId = '${Parameters.workspaceIdFromUrl}'`);
}
if (Parameters.gameIdFromUrl) {
	console.info(`From URL: gameId = '${Parameters.gameIdFromUrl}.`);
}

// optimisation, as devMode is queried often
// eslint-disable-next-line no-underscore-dangle
const _devMode = (Parameters.storageLocation === 'local') || urlParams.get('dev') !== null;

const AnalyticsEnabledKey = 'gc-analyticsEnabled';

const FeatureNames = [
	'hideForum',
	'automaticLogin',
	'paidBasic',
	'hideShareToDuplicate',
	'assumeOnboarded',
	'canManageUsers',
	'canShop',
	'teacher'
] as const;
export type FeatureName = typeof FeatureNames[number];

function organisationsProvidesFeature(org: OrganisationMembershipApi | null, featureName: FeatureName) {
	return roleProvidesFeature(org?.role ?? null, featureName);
}

export class Features {
	private static get(featureName: FeatureName, allowUrlParameterOverride = false) {
		return (
			allowUrlParameterOverride && urlParams.get(featureName) !== null
			||
			loggedInUserInfo && (
				loggedInUserInfo.features.includes(featureName)
				|| productsProvideFeature(loggedInUserInfo.userInfo.products, featureName)
			)
			||
			organisationsProvidesFeature(loggedInUserInfo?.userInfo?.organisationMembership ?? null, featureName)
			||
			false
		);
	}

	// Current feature state
	static readonly devMode = _devMode;
	static readonly localhost = window.location.hostname === 'localhost';
	static readonly masterBranch = window.location.pathname.startsWith('/dev/master/');
	static readonly testAuthentication = Parameters.auth === 'test' || (Features.localhost && !Parameters.storageLocation);
	static readonly experimentalMode = urlParams.get('experimental') !== null;
	static readonly forceGL1 = urlParams.get('gl1') !== null;
	static readonly debugPaint = urlParams.get('debugPaint') !== null;
	static readonly assumeMousePresent = urlParams.get('assumeMousePresent') !== null;
	static readonly barePlayIntro = urlParams.get('barePlayIntro') !== null;
	static readonly hideRuntimeMore = urlParams.get('hideRuntimeMore') !== null;
	static readonly disableFullscreen = urlParams.get('disableFullscreen') !== null;

	static get loggedIn() {
		return loggedInUserInfo !== null;
	}

	static paidOverride = this.localhost;
	static get paid() {
		return Features.paidOverride || (Features.get('paidBasic') ?? false);
	}
	static get automaticLogin() {
		return Features.get('automaticLogin', true);
	}
	static get hideForum() {
		return Features.get('hideForum', true);
	}
	static get hideShareToDuplicate() {
		return Features.get('hideShareToDuplicate', true);
	}
	static get assumeOnboarded() {
		return Features.get('assumeOnboarded', true);
	}
	static get canManageUsers() {
		return Features.get('canManageUsers');
	}
	static get canShop() {
		return Features.get('canShop');
	}
	static get teacher() {
		return Features.get('teacher');
	}

	static get analyticsEnabled() {
		const value = localStorage.getItem(AnalyticsEnabledKey);
		return value !== null ? value === 'true' : true;
	}
	static set analyticsEnabled(value: boolean) {
		localStorage.setItem(AnalyticsEnabledKey, value.toString());
	}

	static pnnsOverride = this.localhost;
	static get pnnScript() {
		return Features.pnnsOverride ||
			(loggedInUserInfo ? loggedInUserInfo.userInfo.groups.includes('f/pnnScript') : false);
	}

	static readonly twoPlayers = true;
}

if (Features.devMode) {
	console.info(`Running in developer mode. (storage = ${Parameters.storageLocation})`);
}


